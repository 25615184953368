<template>
  <list-container-widget
    id="mentimeter"
    :title="widgetName"
    :app-id="100"
    stretch
    class="d-flex"
  >
    <iframe :src="slidoUrl" style="border:none;height: 100%; border-radius: 5px;"></iframe>
  </list-container-widget>
</template>

<script>

import WidgetLayoutMixin from '@core/mixins/widgets/WidgetLayoutMixin';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';

export default {
  name: 'Slido',
  components: { ListContainerWidget },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  computed: {
    slidoUrl() {
			if (this.item?.customizations != null ) {
        if (this.item?.customizations['Slido-link'] != ""){
          return this.item?.customizations['Slido-link'];
        }
			}
      return 'https://app.sli.do/event/';
    },
    item() {
      return this.$store.getters.item('integrations', 'slido');
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'addons',
        customName: 'integrations',
        page: 1,
        requestConfig: {
          orderByDate: -1,
          type: 'integrations',
        },
      });
      this.isLoading = false;
    },
  },
};
</script>
